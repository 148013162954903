import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner-container">
      <h1 className="banner-heading">Visit our dental clinic near Borjhar, VIP</h1>
      <div className="banner-content">
        <div className="banner-item">
          <i className="fas fa-map-marker-alt banner-icon"></i>
          <div>
            <h3>Address</h3>
            <p>Borjhar VIP<br />Guwahati-781124</p>
            <a href="https://www.google.com/maps/dir//1st+floor,+NISHA's+DENTAL+CARE,+near+Quality+Medicos,+opposite+Flyover,+VIP+Chowk,+Azara,+Guwahati,+Assam+781015/@26.1006079,91.5577271,13z/data=!3m1!5s0x375a43df780e0a57:0xb7e12487fc23f7cd!4m9!4m8!1m0!1m5!1m1!1s0x375a43918b071b49:0x5406ca06b140c4ca!2m2!1d91.5990127!2d26.1005367!3e0?entry=ttu" target="_blank" rel="noopener noreferrer">Get Directions</a>
          </div>
        </div>

        <div className="banner-item">
          <i className="fas fa-phone-alt banner-icon"></i>
          <div>
            <h3>Phone</h3>
            <p><a href="tel:+919101067477">(+91) 9101067477</a></p>
          </div>
        </div>

        <div className="banner-item">
          <i className="fas fa-clock banner-icon"></i>
          <div>
            <h3>Hours</h3>
            <p>Monday–Wednesday: 10AM–8PM<br />Friday-Sunday: 10AM–8PM</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
