import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './StudentReviews.css';

const StudentReviews = () => {
  return (
    <div className="student-reviews-container">
      <h2 className="student-reviews-heading">Student Reviews</h2>
      <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
        <div>
          <img src="/studentreviews/1.jpeg" alt="Student 1" />
         
        </div>
        <div>
          <img src="/studentreviews/2.jpeg" alt="Student 2" />
         
        </div>
        <div>
          <img src="/studentreviews/3.jpeg" alt="Student 3" />
         
        </div>
        <div>
          <img src="/studentreviews/4.jpeg" alt="Student 4" />
         
        </div>
        <div>
          <img src="/studentreviews/5.jpeg" alt="Student 5" />
         
        </div>

      </Carousel>
    </div>
  );
};

export default StudentReviews;
