import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
    return (
        <div className="about-page">
            <div className="about-section">
                <h2>About Us</h2>

                <p>
                    Welcome to NISHA's DENTAL CARE, your one-stop treatment center committed to providing the highest quality of clinical care and personalized service in a warm and compassionate atmosphere. We pride ourselves on our dedication to excellence in dental care, ensuring that every patient receives tailored treatments to meet their unique needs.
                    <br /> <br />
                    <b>Our Mission</b> <br />
                    At NISHA's DENTAL CARE, our mission is to offer exceptional dental services that enhance the health and beauty of your smile. We strive to create a comfortable and welcoming environment where patients can feel at ease, knowing they are in the hands of experienced and caring professionals.
                    <br /> <br />
                    <b>About Dr. Nisha Talukdar</b> <br />
                    Dr. Nisha Talukdar, our certified cosmetic dental surgeon, brings a wealth of experience and expertise to NISHA's DENTAL CARE. Her impressive credentials include:
                    <br />
                   
                    <ul>
                        <li>Certified Cosmetic Dental Surgeon</li>
                        <li>Ex-Resident at Fakhruddin Ali Ahmed Medical College & Hospital, Barpeta</li>
                        <li>Former Consultant at Niranjan Dental Clinic, Barpeta
                        </li>
                        <li>Ex-Consultant at True Smile Dental Clinic, Bamunimaidam, Guwahati
                        </li>
                    </ul>
                    <br />
                    <br />
                    <b>Why Choose Us?</b>
                    <ul>
                        <li>
                        Comprehensive Care: From routine check-ups to advanced cosmetic procedures, we offer a wide range of dental services to meet all your oral health needs.
                        </li>
                        <li>Personalized Service: We believe in treating each patient as an individual, tailoring our services to provide the best possible outcomes.</li>
                        <li>Warm and Compassionate Atmosphere: Our clinic is designed to make you feel comfortable and relaxed, with a friendly team ready to assist you every step of the way.</li>
                        <li>Expertise and Experience: With Dr. Nisha Talukdar at the helm, you can trust that you are receiving top-tier care from a highly qualified professional.</li>
                    </ul>
                    
                    <br />
                    <b>Visit Us</b> <br />
                    We invite you to visit NISHA's DENTAL CARE and experience our commitment to excellence in dental care. Whether you are looking for routine dental maintenance or seeking advanced cosmetic treatments, we are here to help you achieve a healthy, beautiful smile.
                    <br /> <br />
                    <hr /> <br /> <br />
                    We look forward to welcoming you to NISHA's DENTAL CARE. Please don't hesitate to contact us for more information or to schedule an appointment. Your smile is our top priority!
                </p>

            </div>


            {/* <div className="team-section">
                <h2>Current Interns</h2>
                <div className="team-cards">
                    <div className="team-card">
                        <img src="https://randomuser.me/api/portraits/women/44.jpg" alt="Dr. John Doe" className="team-image" />
                        <h3>Dr. John Doe</h3>
                        <p>Orthodontist</p>
                    </div>
                    <div className="team-card">
                        <img src="https://randomuser.me/api/portraits/women/45.jpg" alt="Dr. Jane Smith" className="team-image" />
                        <h3>Dr. Jane Smith</h3>
                        <p>Dentist</p>
                    </div>
                    <div className="team-card">
                        <img src="https://randomuser.me/api/portraits/men/44.jpg" alt="Dr. Michael Johnson" className="team-image" />
                        <h3>Dr. Michael Johnson</h3>
                        <p>Oral Surgeon</p>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default AboutPage;
