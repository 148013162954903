import React from 'react';
import './MembershipBanner.css';

const MembershipBanner = () => {
  return (
    <div className="membership-banner-container">
      <h2 className="membership-banner-heading">Membership at Nisha's Dental Care</h2>
      <div className="membership-banner-content">
        <div className="membership-banner-item">
          <i className="fas fa-user-plus membership-banner-icon"></i>
          <div>
            <h3>Join Our Membership</h3>
            <p>Sign up today and enjoy:</p>
            <ul>
              <li>Free checkup for the next 6 months</li>
              <li>10% discount on all dental procedures</li>
              <li>Priority scheduling for appointments</li>
              <li>Exclusive access to new treatments</li>
            </ul>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6L5pJBVpkC3n5TIffj95Rs-bBg-uXXcp5i3571yA13Uab6w/formResponse" className="button-30">Apply For Membership</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipBanner;
