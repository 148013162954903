import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      message: formData.get('message'),
    };

    try {
      const response = await fetch('https://api-service.webartstudio.online/nishasdentalcare/coursesform.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true);
        // Reset form fields or close form
        e.target.reset();
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="contact-section" id='contact'>
      <div className="contact-container">
        <div className="contact-info">
          <div className="info-item">
            <h3>Address</h3>
            <p>Borjhar, VIP Chowk</p>
          </div>
          <div className="info-item">
            <h3>Phone Number</h3>
            <a href="tel:+919101067477">(+91) 9101067477</a>
          </div>
        </div>
        <div className="contact-form">
          <h2>Contact Us</h2>
          {submitted ? (
            <p className="form-success">Message successfully sent!</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" rows="5" required></textarea>
              </div>
              <button type="submit" className="submit-button" disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
