import React from 'react'
import Navbar from './components/navbar/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Footer from './components/footer/Footer';
import AboutPage from './pages/about/AboutPage';
import Services from './components/services/Services';
import Blogs from './pages/blogs/Blogs';
import Certifications from './pages/certifications/Certifications';
import Courses from './pages/courses/Courses';

const App = () => {
  return (
    <Router>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/about" element={<AboutPage/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/blogs" element={<Blogs/>} />
      <Route path="/courses" element={<Courses/>} />
      <Route path="/certifications" element={<Certifications/>} />
    </Routes>
    <Footer/>
  </Router>
  )
}

export default App