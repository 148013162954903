import React from 'react'
import HeroSection from '../../components/herosection/HeroSection'
import Services from '../../components/services/Services'
import StatisticsSection from '../../components/statistics/StatisticsSection'
import Testimonials from '../../components/testimonials/Testimonials'
import Banner from '../../components/banner/Banner'
import MembershipBanner from '../../components/banner/MembershipBanner'
import Courses from '../courses/Courses'
import Contact from '../contact/Contact'
import OurWork from '../../components/ourwork/OurWork'

const Home = () => {
  return (
   <>
   <HeroSection/>
   <Services/>
   <Banner/>
   <Courses/>
   <Testimonials/>
   <StatisticsSection/>
   <OurWork/>
   <Contact/>
   <MembershipBanner/>
   </>
  )
}

export default Home