import React from 'react';
import './Services.css';

const services = [
  { title: "Root Canal", description: "Expert care to relieve pain." },
  { title: "Crowns & Bridges", description: "Restore function and beauty." },
  { title: "Child Care", description: "Friendly care for kids." },
  { title: "Dental Implants", description: "Natural and durable solutions." },
  { title: "Braces", description: "Straighter teeth, confident smile." },
  { title: "Removable Denture", description: "Comfortable and flexible." },
  { title: "Fixed Denture", description: "Stable and natural look." },
  { title: "Complete Denture", description: "Full smile restoration." },
  { title: "Teeth Whitening", description: "Brighten your smile." },
  { title: "Veneers", description: "Perfect your smile." },
];

const Services = () => {
  return (
    <div className="services-section" id="services">
      <h1 className="services-title">Our Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <h2 className="service-title">{service.title}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
