import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Courses.css';
import StudentReviews from '../../components/studentreviews/StudentReviews';

const courseBanners = [
  { src: '/banners/banner1.jpeg', alt: 'Course 1' },
  { src: '/banners/banner4.jpeg', alt: 'Course 4' },
  { src: '/banners/banner2.jpeg', alt: 'Course 2' },
  { src: '/banners/banner3.jpeg', alt: 'Course 3' },
];

const Courses = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      course: formData.get('course'),
      message: formData.get('message'),
    };

    try {
      const response = await fetch('https://api-service.webartstudio.online/nishasdentalcare/coursesform.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true);
        // Reset form fields or close form
        e.target.reset();
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='courses'>
      <div className="courses-section">
        <div className="carousel-container">
          <h1 className="courses-title">Courses offered by us</h1>
          <Carousel autoPlay infiniteLoop showThumbs={false}>
            {courseBanners.map((banner, index) => (
              <div key={index}>
                <img src={banner.src} alt={banner.alt} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="application-form">
          <h2>For enquiry. Fill up the details</h2>
          {submitted ? (
            <p className="form-success">Message successfully sent!</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input type="text" name="name" required />
              </label>
              <label>
                Email:
                <input type="email" name="email" required />
              </label>
              <label>
                Course:
                <input type="text" name="course" required />
              </label>
              <label>
                Message:
                <textarea name="message" required></textarea>
              </label>
              <button type="submit" disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          )}
        </div>
      </div>

      <StudentReviews />
    </div>
  );
};

export default Courses;
