import React, { useEffect } from 'react';
import './Blogs.css';

const Blogs = () => {
  const instagramPosts = [
    "https://www.instagram.com/p/C9MZq2xBU_b/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/p/C86JHKqhx-R/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/p/C8qs-M3ph81/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/reel/C8op7CcPpNh/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/reel/Cz-q6y8vu2N/?utm_source=ig_embed&amp;utm_campaign=loading"
  ];

  useEffect(() => {
    const loadInstagramEmbed = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      } else {
        const script = document.createElement('script');
        script.async = true;
        script.src = '//www.instagram.com/embed.js';
        document.body.appendChild(script);
      }
    };

    loadInstagramEmbed();
  }, []);

  return (
    <div className="blogs-section">
      <h2>Informative Contents</h2>
      <div className="card-container">
        {instagramPosts.map((post, index) => (
          <div key={index} className="card">
            <blockquote className="instagram-media" data-instgrm-permalink={post} data-instgrm-version="14" style={{ background:'#FFF', border:0, borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin:'1px', maxWidth:'540px', minWidth:'326px', padding:0, width:'99.375%', width:'calc(100% - 2px)' }}>
              <a href={post} style={{ background:'#FFFFFF', lineHeight:0, padding:0, textAlign:'center', textDecoration:'none', width:'100%' }} target="_blank" rel="noopener noreferrer"></a>
            </blockquote>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
