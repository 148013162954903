import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Testimonials.css';

const Testimonials = () => {
  const embedInstagram = () => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "//www.instagram.com/embed.js";
    script.onload = embedInstagram;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const instagramPosts = [
    'https://www.instagram.com/tv/Cq2LiLjPJMa/?utm_source=ig_embed&amp;utm_campaign=loading',
    'https://www.instagram.com/reel/CsVluYAhK3E/?utm_source=ig_embed&amp;utm_campaign=loading',
    'https://www.instagram.com/reel/C0i6aepCHUp/?utm_source=ig_embed&amp;utm_campaign=loading',
    'https://www.instagram.com/reel/C8_TaS-Jkq3/?utm_source=ig_embed&amp;utm_campaign=loading'
  ];

  return (
    <div className="testimonials">
      <h2>Client Testimonials</h2>
      <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows autoPlay>
        {instagramPosts.map((post, index) => (
          <div key={index} className="instagram-container">
            <blockquote
              className="instagram-media"
              data-instgrm-permalink={post}
              data-instgrm-version="14"
              style={{
                background: '#FFF',
                border: '0',
                borderRadius: '3px',
                boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                margin: '1px',
                maxWidth: '540px',
                minWidth: '326px',
                padding: '0',
                width: '99.375%',
                width: '-webkit-calc(100% - 2px)',
                width: 'calc(100% - 2px)'
              }}
            >
              <div style={{ padding: '16px' }}>
                <a href={post} style={{ background: '#FFFFFF', lineHeight: '0', padding: '0 0', textAlign: 'center', textDecoration: 'none', width: '100%' }} target="_blank" rel="noopener noreferrer">
                  View this post on Instagram
                </a>
              </div>
            </blockquote>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
