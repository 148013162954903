import React from 'react';
import './Certifications.css';

// Define the certification data as a JavaScript constant
const certificationsData = [
    // {
    //     title: "Certificate Title 1",
    //     description: "This is a description of the first certificate.",
    //     date: "March 1, 2023",
    //     imageUrl: "https://templatelab.com/wp-content/uploads/2018/11/Certificate-employee-1-e1542525135159.jpg"
    // },
    {
        imageUrl: "/certificates/9.jpeg"
    },
    {
        imageUrl: "/certificates/10.jpeg"
    },
    {
        imageUrl: "/certificates/11.jpeg"
    },
    {
        imageUrl: "/certificates/17.jpeg"
    },
    {
        imageUrl: "/certificates/14.jpeg"
    },
    {
        imageUrl: "/certificates/13.jpeg"
    },
    {
        imageUrl: "/certificates/12.jpeg"
    },
    {
        imageUrl: "/certificates/8.jpeg"
    },
    {
        imageUrl: "/certificates/1.jpeg"
    },
    {
        imageUrl: "/certificates/2.jpeg"
    },
    {
        imageUrl: "/certificates/3.jpeg"
    },
    {
        imageUrl: "/certificates/4.jpeg"
    },
    {
        imageUrl: "/certificates/5.jpeg"
    },
    {
        imageUrl: "/certificates/6.jpeg"
    },
    {
        imageUrl: "/certificates/7.jpeg"
    },
    {
        imageUrl: "/certificates/16.jpeg"
    },
   
];

const Certifications = () => {
    return (
        <div className="certifications-container">
            <h2 className="certifications-heading">Achievements & Certifications</h2>
            <div className="certificates">
                {certificationsData.map((certificate, index) => (
                    <div key={index} className="certificate">
                        <img 
                            src={certificate.imageUrl} 
                            alt={`Certificate ${index + 1}`} 
                            className="certificate-image" 
                        />
                        {/* <div className="certificate-details">
                            <h3 className="certificate-title">{certificate.title}</h3>
                            <p className="certificate-description">{certificate.description}</p>
                            <p className="certificate-timing">Received on: {certificate.date}</p>
                        </div> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Certifications;
