import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './OurWork.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const OurWork = () => {
  const instagramPosts = [
    "https://www.instagram.com/reel/C7Tt9CgRm7n/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/reel/C8_TaS-Jkq3/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.instagram.com/reel/CrcjSjwtW6u/?utm_source=ig_embed&amp;utm_campaign=loading"
  ];

  useEffect(() => {
    // Ensure Instagram embeds script is loaded
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);
  }, []);

  return (
    <div className="our-work-section">
      <h2>Our Work</h2>
      <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
        {instagramPosts.map((post, index) => (
          <div key={index} className="carousel-item">
            <blockquote className="instagram-media" data-instgrm-permalink={post} data-instgrm-version="14" style={{ background:'#FFF', border:0, borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin:'1px', maxWidth:'540px', minWidth:'326px', padding:0, width:'99.375%', width:'calc(100% - 2px)' }}>
              <a href={post} style={{ background:'#FFFFFF', lineHeight:0, padding:0, textAlign:'center', textDecoration:'none', width:'100%' }} target="_blank" rel="noopener noreferrer"></a>
            </blockquote>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default OurWork;
